<template>
    
        <div class="hp-bg-black-20 hp-bg-color-dark-90 col-lg-6 col-12 acNone" style="margin-top: 0px;">
            <div class="row hp-image-row h-100 px-8 px-sm-16 px-md-0 pb-32 pb-sm-0 pt-32 pt-md-0">
                <div class="hp-logo-item m-16 m-sm-32 m-md-64 w-auto px-0 col-12">
                    <div class="hp-header-logo d-flex align-items-center" >
                        <a href="https://www.wavestake.com" target="_blank" class="position-relative">
                            

                            <div style="width: 100%; display: flex; align-items: center; justify-content: center;margin-left: 150px;">

                                <img class="hp-logo hp-sidebar-visible hp-dark-none" src="@/assets/img/logoPrimary.png" alt="logo" style="width: 200px; height: 80px;">
                                <img class="hp-logo hp-sidebar-visible hp-dark-block" src="@/assets/img/logoLong.png" alt="logo" style="width: 200px; height: 80px;">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-none" src="@/assets/img/logoLong.png" alt="logo" style="width: 200px; height: 80px;">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-block" src="@/assets/img/logoLong.png" alt="logo" style="width: 200px; height: 80px;">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-none" src="@/assets/img/logoLong.png" alt="logo" style="width: 200px; height: 80px;">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-block" src="@/assets/img/logoLong.png" alt="logo" style="width: 200px; height: 80px;">

                            </div>
                        </a>

                        
                    </div>
                </div>

                <div class="col-12 px-0">
                    <div class="row h-100 w-100 mx-0 align-items-center justify-content-center">
                        <div class="hp-bg-item text-center mb-32 mb-md-0 px-0 col-12">
                            <img class="hp-dark-none m-auto w-100" src="@/assets/img/acbg2.svg" alt="Background Image">
                            <img class="hp-dark-block m-auto w-100" src="@/assets/img/acbg2.svg" alt="Background Image">
                        </div>

                        <div class="hp-text-item text-center col-xl-9 col-12" style="margin-top: -400px;">
                            <h2 class="hp-text-color-black-100 hp-text-color-dark-0 mx-16 mx-lg-0 mb-16"> Finance without borders </h2>
                            <p class="h4 mb-0 fw-normal hp-text-color-black-80 hp-text-color-dark-30"> We are on a mission to liberate global wealth and empower people across the globe to create a better future using blockchain technology. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-6 py-sm-64 py-lg-0" style="margin-top: 50px;">
            <div class="row align-items-center justify-content-center h-100 mx-4 mx-sm-n32">
                <div class="col-12 col-md-9 col-xl-7 col-xxxl-5 px-8 px-sm-0 pt-24 pb-48">
                    <span class="d-block hp-p1-body hp-text-color-dark-0 hp-text-color-black-100 fw-medium mb-6" style="margin-bottom: 10px;"> SIGN UP FOR FREE </span>
                    <h1 class="mb-0 mb-sm-24">Create Account</h1>
                    <p class="mt-sm-8 mt-sm-0 text-black-60" style="margin-top: 5px;">Join Wavestake and start building your digital wealth on the self-custodial wallet of the future</p>

                    <form class="mt-16 mt-sm-32 mb-8" @submit.prevent="signUp">
                        <div class="mb-16" >
                            <label for="registerUsername" class="form-label" style="margin-top: 10px;">Legal name :</label>
                            <input type="text" class="form-control" required v-model="name" placeholder="First and last name">
                        </div>

                        <div class="mb-16">
                            <label for="registerEmail" class="form-label">E-mail :</label>
                            <input type="email" class="form-control" required v-model="email" placeholder="hello@example.com" >
                        </div>

                        <div class="mb-16">
                            <label for="registerPassword" class="form-label">Password :</label>
                            <input type="password" class="form-control" required v-model="password" placeholder="Minimum 8 characters">
                        </div>

                        

                        <div class="mb-16">
                            <label for="registerConfirmPassword" class="form-label">Referral code :</label>
                            <input type="text" class="form-control" v-model="uplineCode" placeholder="XXXXXXXXX">
                        </div>

                        <span class="text-black-80 hp-text-color-dark-40 hp-caption me-4" style="font-style: italic;" >By signing up I confirm that I am 18 years of age or older and agree to the User Agreement and Privacy Policy</span>
                        

                        <button type="submit" class="btn btn-primary w-100 " style="padding: 20px; color: #000000; margin-top: 30px;">
                            Sign Up
                        </button>
                    </form>

                    <div class="col-12 hp-form-info text-center" >
                        <span class="text-black-80 hp-text-color-dark-40 hp-caption me-4">Already have an account?</span>
                        <router-link :to="{name: 'signin'}" class="text-primary-1 hp-text-color-dark-primary-2 hp-caption" >Sign in</router-link>
                    </div>

                    <div class="mt-48 mt-sm-96 col-12" >
                        <p class="hp-p1-body text-center hp-text-color-black-60 mb-8"> Copyright 2023 Wavestake. </p>

                        <div class="row align-items-center justify-content-center mx-n8">
                            <div class="w-auto hp-flex-none px-8 col">
                                <a href="https://www.wavestake.com/privacy" target="_blank" class="hp-text-color-black-80 hp-text-color-dark-40"> Privacy Policy </a>
                            </div>

                            <div class="w-auto hp-flex-none px-8 col">
                                <a href="https://www.wavestake.com/user-agree" target="_blank" class="hp-text-color-black-80 hp-text-color-dark-40"> User Agreement </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--********** MODALS **********-->

        <!-- SUCCESS -->
        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">
            <div class="col-12 col-xl-4" style="width: 300px;">
                <div class="card hp-card-6">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 text-center">
                                <img src="@/assets/img/success.png" alt="Upgrade Account" class="hp-dark-block mx-auto">

                                <h3 class="mt-24 mb-16 me-4 text-success">Success!</h3>
                                <p class="hp-p1-body mb-0" style="margin-top: -10px;">{{ successText }}</p>

                                <button class="btn btn-sm btn-primary" style="color: #000000; font-weight: 500; border-style: none; margin-top: 20px;" @click="closeS">Okay</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ERROR -->
        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

            <div class="col-12 col-xl-4" style="width: 300px;">
                <div class="card hp-card-6">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 text-center">
                                <img src="@/assets/img/error.png" alt="Upgrade Account" class="hp-dark-block mx-auto">

                                <h3 class="mt-24 mb-16 me-4 text-danger">Error!</h3>
                                <p class="hp-p1-body mb-0" style="margin-top: -10px;">{{ errorText }}</p>

                                <button class="btn btn-sm btn-primary" style="color: #000000; font-weight: 500; border-style: none; margin-top: 20px;" @click="closeE">Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- LOADING -->
        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg " ref="loadingModal">
            <div class="col-12 col-xl-4" style="width: 300px;">
                <div class="card hp-card-6">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 text-center">
                                <img src="@/assets/img/loading.png"  class="hp-dark-block mx-auto">

                                        <!-- <h3 class="mt-24 mb-16 me-4 text-danger"></h3> -->

                                <div class="spinner-border" style="width: 3rem; height: 3rem; border-color: #cbfb45;" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>

                                <p class="hp-p1-body mb-0" style="margin-top: 10px;">{{loadingText}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

</template>

<script>

    import { doc, setDoc, collection, query, where, getDocs, serverTimestamp, updateDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';

    export default {
        data(){
            return{
                name: "",
                email: "",
                password: "",
                uplineCode: "",
                loadingText: "",
                errorText: "",
                successText: "",
            }
        } ,

        mounted(){
          this.getUplineCode();  
        },
        
        methods: {
            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
            },

            getUplineCode(){
                let urlString = String(window.location.href);
                let url = new URL(urlString);
                let ref = url.searchParams.get("ref");
                if(ref == null){
                    return;
                }
                this.uplineCode = String(ref);
            },

            async signUp(){

                //start loading
                this.loadingText= "Creating account...";
                this.$refs.loadingModal.classList.add("bg-active");

                //get verify pin
                let verifyPin = String(Math.floor(Math.random()*90000) + 10000);

                //get pin expiry
                let pinExpiry = (new Date().getTime() / (1000 * 60)) + 30;

                //get userId
                let userId = this.name + String(Math.floor(Math.random()*90000000) + 10000000);

                //get referral code
                let referralCode = "WS"+ String(Math.floor(Math.random()*9000000000) + 1000000000);

                //password length
                if(!(this.password.length >= 8)){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Password must be 8 characters or more";
                    console.log("ERROR");
                    return;
                }

                //check if email exists
                const usersRef = collection(db, "Users");
                const q = query(collection(db, "Users"), where("email", "==", this.email.toLowerCase()));

                const querySnapshot = await getDocs(q);
                if(querySnapshot.docs.length > 0){
                this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Email already exists";
                    console.log("ERROR");
                    return; 
                }

                //fire sign up
                setDoc(doc(db, "Users", String(userId)), {
                    email : this.email.toLowerCase(),
                    name : this.name,
                    password : this.password,
                    profilePic : "https://firebasestorage.googleapis.com/v0/b/wstake-9e6f7.appspot.com/o/accountAssets%2Fprou.png?alt=media&token=3a92aa98-2ba7-4996-93d7-94c6b2eadce9",
                    userId : userId,
                    verified : "no",
                    auth: "no",
                    dateJoined: serverTimestamp(),
                    phrase: "",
                    refCode: referralCode,
                    waveStakeId: referralCode,
                    totalRefs: 0,
                    refBalance: 0,
                    totalTrans: 0,
                    totalSent: 0,
                    totalReceived: 0,
                    upline: this.uplineCode,
                    kycType: "None",
                    kycStatus: "Rejected",
                    kycFrontUrl: "",
                    kycBackUrl: "",
                })
                .then(() => {

                //save id
                sessionStorage.setItem('id', String(userId));
                sessionStorage.setItem('name', String(this.name));
                sessionStorage.setItem('email', String(this.email.toLowerCase()));
                sessionStorage.setItem('verifyPin', verifyPin);
                sessionStorage.setItem('pinExpiry', pinExpiry);
                sessionStorage.setItem('upline', String(this.uplineCode));

                let name = this.name;

                //send verification pin
                Email.send({
                    SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
                    To: String(this.email),
                    From: "no-reply@wavestake.com",
                    Subject: "Email Verification",
                    Body : `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <!--[if gte mso 9]>
        <xml>
            <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
        </xml>
        <![endif]-->

        <meta http-equiv="Content-type" content="text/html; charset=utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="format-detection" content="date=no"/>
        <meta name="format-detection" content="address=no"/>
        <meta name="format-detection" content="telephone=no"/>
        <meta name="x-apple-disable-message-reformatting"/>

        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap" rel="stylesheet">

        <title>Verify</title>
    </head>

    <body style="margin: 0; padding: 0; font-family: 'Manrope', sans-serif; min-height: 100vh; width: 100vw; background: #1F1F1F;">
        <center>
            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="background: #1F1F1F; margin-top: -60px;">
                <tr>
                    <td align="center">
                        <table width="380px" border="0" cellspacing="0" cellpadding="0">
                            <tr>
                                <td style="padding: 35px">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td style="text-align:center;  padding: 42px 15px 16px;">
                                                <a href="#" target="_blank">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/wstake-9e6f7.appspot.com/o/emailAssets%2FlogoLong.png?alt=media&token=cf8009a9-a03a-495b-a1a9-0bd2978c1bbf" style="width: 50%;" border="0" alt="Logo"/>
                                                </a>
                                            </td>
                                        </tr>

                                        

                                        <tr>
                                            <td style="border-radius: 8px;" bgcolor="#141414">
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                    <tr>
                                                        <td style="font-size:24px; color:#CBFB45; text-align: center; min-width:auto !important; font-weight: bold; padding: 32px 32px 0;">
                                                           Verify Your Email
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td style="font-size:14px; color:#ffffff; min-width:auto !important; line-height: 20px; padding: 32px;">
                                                            Hello ${name},
                                                            <br/>
                                                            <br/>
                                                            Welcome to Wavestake!
                                                            <br/>
                                                            You are receiving this email because you have signed up on our platform.
                                                            <br/>
                                                            <br/>
                                                            Use the pin below to verify your email and activate your account.
                                                            <br/>
                                                            <br/>
                                                            <h1 style="color: #CBFB45; text-align: center;">${verifyPin}</h1>
                                                            <br>
                                                         
                                                            This pin will expire in 15 minutes and can only be used once.
                                                            <br/>
                                                            
                                                            
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        
                                                        <td style="font-size:10px; color:#ffffff; min-width:auto !important; line-height: 20px; padding: 0 32px 32px;">

                                                            <hr>
                                                            <br>
                                                            <br>
                                                            
                                                            If you did not make this request, please contact us or ignore this message.
                                                            <br/>
                                                            <br/>
                                                            This is an automatically generated email please do not reply to this email. If you face any issues, please contact us at <a href="mailto:help@wavestake.com" style="color: #CBFB45;">help@wavestake.com</a>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="font-size:12px; color:#B2BEC3; min-width:auto !important; line-height: 12px; text-align:center; padding-top: 42px;">
                                                Copyright © 2023
                                                <a href="https://www.wavestake.com/" target="_blank" style="text-decoration:none; color:#CBFB45;">Wavestake.com</a>
                                                All rights reserved.
                                            </td>
                                        </tr>

                                        
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </center>

    

</body>
</html>`
                }).
                then((message) => {
                    //console.log(message);
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$router.push({name: 'verify'});

                }, (error) => {
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "An error occured while sending the verification pin";
                    console.log(error);
                    return;
                });

            })
            .catch(error =>{
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.errorModal.classList.add("bg-active");
                this.errorText = "An error occured while creating your account";
                console.log(error);
            })

            }
        }
    }

</script>