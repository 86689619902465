<template>

  <div class="row hp-authentication-page">
    
    <router-view/>

  </div>

</template>

<style>

  @import url(@/assets/css/swiper-bundle.min.css);
  @import url(@/assets/css/index.min.css);
  @import url(@/assets/css/index2.min.css);
  @import url(@/assets/css/bootstrap.css);
  @import url(@/assets/css/colors.css);
  @import url(@/assets/css/typography.css);
  @import url(@/assets/css/base.css);
  @import url(@/assets/css/colors-dark.css);
  @import url(@/assets/css/theme-dark.css);
  @import url(@/assets/css/custom-rtl.css);
  @import url(@/assets/css/sider.css);
  @import url(@/assets/css/header.css);
  @import url(@/assets/css/page-content.css);
  @import url(@/assets/css/components.css);
  @import url(@/assets/css/authentication.css);
  @import url(@/assets/css/style.css);

</style>
