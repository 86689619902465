import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB-7PYMje8m_pZXi6dayM5MCj2hJHed_EA",
  authDomain: "wstake-9e6f7.firebaseapp.com",
  projectId: "wstake-9e6f7",
  storageBucket: "wstake-9e6f7.appspot.com",
  messagingSenderId: "872913470295",
  appId: "1:872913470295:web:30ecffeeb01004cfe8f412",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
