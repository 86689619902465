
import { defineComponent } from 'vue';
import SignupBody from '@/components/SignupBody.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'SignupView',
  components: {
    SignupBody,
  },
});
