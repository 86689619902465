import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import SignupView from '../views/SignupView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "signup",
    component: SignupView,
  },
  {
    path: "/sign-up",
    name: "signup2",
    component: () => import("../views/SignupView.vue"),
  },

  {
    path: "/verify-email",
    name: "verify",
    component: () => import("../views/VerifyView.vue"),
  },

  {
    path: "/recovery-phrase",
    name: "phrase",
    component: () => import("../views/PhraseView.vue"),
  },

  {
    path: "/sign-in",
    name: "signin",
    component: () => import("../views/SigninView.vue"),
  },

  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import("../views/ForgotPasswordView.vue"),
  },

  {
    path: "/forgot-password2",
    name: "forgotPassword2",
    component: () => import("../views/ForgotPasswordView2.vue"),
  },

  {
    path: "/reset-password",
    name: "resetPassword",
    component: () => import("../views/ResetPasswordView.vue"),
  },

  {
    path: "/sign-in-verification",
    name: "auth",
    component: () => import("../views/AuthView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
